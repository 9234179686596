.text3 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 160% */

    display: flex;
    align-items: center;
    letter-spacing: 0.75px;

    /* Grayscale / Label */

    color: #6E7191;
}
.text2 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    /* identical to box height, or 169% */

    display: flex;
    align-items: center;
    letter-spacing: 0.25px;

    /* Grayscale / Label */

    color: #6E7191;
}
.text1 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 34px;
    /* identical to box height, or 106% */

    letter-spacing: 1px;
    /*background: linear-gradient(95.08deg, #F80179 2.49%, #640EB3 97.19%);*/
    /*-webkit-background-clip: text;*/
    /*-webkit-text-fill-color: transparent;*/
    color: #032596;

}
.card {
    background: rgba(252, 252, 252, 0.5);
    border-radius: 32px;
    padding:  10px;
    padding-left:  32px;
    /*max-height: 151px;*/
    margin-bottom: 9px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}
.background {
    padding: 12px;
    min-height: 100vh;
    /*background: linear-gradient(95.08deg, #F639D1 2.49%, #AB2EFF 46.38%, #00C7FF 97.19%);*/
    background: linear-gradient(95.08deg, #EDF7F8 2.49%, #B5C7E1 97.19%);
}
.icon{
    position: absolute;
    top:10px;
    right:10px;
}
.iconSide{
    /*position: absolute;*/
    /*left:0px;*/
}

.iconContainer{
    display: flex;
    align-items: center;

}


.loadingModal {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;
    background-color: gray;
    opacity: 0.7;
    width: 100vw;
    height: 100vh;
}

.tabs{
    margin-bottom:10px;
}

.loadingContainer{
    border-radius: 32px;
    background: white;
    padding: 20px;
    margin:  0 20px;
    display: flex;
    justify-content: center;
}